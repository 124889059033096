.loginPopup {
  width: 601px;
  height: 145px;
  border-radius: 8px;
  border: 1px solid var(--FRM-Neutral-neutral-100, #D1D1D3);
  background: var(--FRM-White, #FFF);
  box-shadow: 4px 4px 32px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: var(--FRM-Neutral-neutral-900, #2C2D30);
  /* Label Medium */
  font-family: "Atkinson Hyperlegible";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  letter-spacing: 0.32px;
}

.buttonBox {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-content: center;
  gap: 4px;
}

.logButton {
  margin: 9px;
  width: 113px;
  height: 41px;
  flex-shrink: 0;
  gap: 8px;
  border-radius: 12px;
  border: 1px solid #D1D1D3;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  padding: 17px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background: var(--FRM-Primary-primary-500, #2067D2);
  color: white;
}

.closeBox {
  width: 20px;
  height: 20px;
  position: absolute;
  margin-left: 565px;
  margin-top: -110px;
  cursor: pointer;
}

.loginDialog {
  border-radius: 8px;
  overflow: hidden;
}


/* Responsive to Mobile Mode */
@media (max-width: 601px){
  .loginPopup {
    width:601px;
    height: 145px;
    max-width: 90%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .buttonBox {
    flex-direction: row;
    justify-content: center;
    gap: 8px;
  }

  .logButton {
    width: 113px;
  }
}

